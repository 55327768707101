<template>
  <div id="error">
    <p>404，找不到页面！</p>
    <a-button @click="onReturnHome">返回上一页</a-button>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {
    onReturnHome () {
      this.$router.go(-1)
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
p{
  text-align: center;
  font-size: 28px;
  color: #999;
  font-weight: bold;
  padding: 10% 0 30px 0;
}
button{
  margin: 0 auto;
  display: block;
}
</style>
